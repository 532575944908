import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import Spinner from 'react-bootstrap/Spinner';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={
      <div className='loader'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>}
    >
      <App />
    </Suspense>
  </React.StrictMode>
);